import axios from 'axios'

export const urlPrefixAPI = process.env.VUE_APP_URL_Prefix_API

const apiURL = urlPrefixAPI + '/'
const timeout = 6000

export const userAPI = axios.create({
  baseURL: apiURL + 'user/',
  withCredentials: true,
  timeout: timeout
})

export const padAPI = axios.create({
  baseURL: apiURL + 'pad/',
  withCredentials: true,
  timeout: timeout
})
