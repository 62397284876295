export const APICode = {
  Success: 0,
  ParamFormatError: 1,
  ValidateFailed: 2,
  BadRequest: 4,
  ServerError: 5,
  Unauthorized: 6,
  NotImplement: 7,
  Limit: 8,
  NoPermission: 9,
  NotFound: 10,
  Exist: 11
}

export const responseWrapper = async function (axiosPromise) {
  let response = null
  let errMsg = null
  try {
    const resp = await axiosPromise
    response = resp.data
  } catch (error) {
    if (error.response) {
      errMsg = { resp: error.response.data }
    } else if (error.request && error.message.includes('timeout')) {
      errMsg = { isTimeout: true }
    } else {
      errMsg = new Error(error.message)
      console.log('error config: ', error.config)
      console.log('response error message: ', error.message)
    }
  }
  if (response && response.code !== APICode.Success) errMsg = { resp: response }
  if (errMsg) {
    console.log('errMsg: ', errMsg)
    throw errMsg
  }
  return response
}
