import { APICode } from '@/api/common'

export function dealOtherError (instance, error) {
  if (error.resp) {
    if (error.resp.code === APICode.ParamFormatError) {
      instance.$message({
        showClose: true,
        type: 'error',
        message: '请求参数错误！错误信息：' + error.resp.message,
        duration: 0
      })
      return
    }
    if (error.resp.code === APICode.Unauthorized) {
      instance.$router.push({ name: 'Login' }).then(() => {
        setTimeout(() => {
          instance.$message({
            showClose: true,
            type: 'error',
            message: '登录会话过期，请重新登录！',
            duration: 5000
          })
        }, 1000)
      })
      return
    }
    instance.$message({
      showClose: true,
      type: 'error',
      message: '出错了！错误：' + JSON.stringify(error.resp),
      duration: 0
    })
  } else if (error.isTimeout) {
    instance.$message({
      showClose: true,
      type: 'error',
      message: '连接超时！请检查网络是否通畅'
    })
  } else {
    instance.$message({
      showClose: true,
      type: 'error',
      duration: 0,
      message: '发生未知错误！请联系管理员。错误详情：' + error.message
    })
  }
}
