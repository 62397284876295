import { responseWrapper } from '@/api/common'
import { padAPI } from '@/api/config'

/**
 * CreatePad Response
 * @typedef {Object} CreatePadResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {String} data.padID
 */

/**
 * CreatePad api
 * @param {String} padName
 * @returns {CreatePadResponse}
 * @example
 * const response = await api.createPad('my new pad')
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "padID": "V4f5zYX6uG"
 *     },
 *     "message": "Success"
 * }
 */
const createPad = async function (padName) {
  return await responseWrapper(padAPI({
    url: '/create',
    method: 'post',
    data: {
      padName: padName
    }
  }))
}

/**
 * OpenPadMeta
 * @typedef {Object} OpenPadMeta
 * @property {String} padID
 * @property {String} padName
 * @property {String} owner owner user ID
 */

/**
 * OpenPad Response
 * @typedef {Object} OpenPadResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {OpenPadMeta} data.padMeta
 * @property {String} data.iframeLink
 * @property {String} data.sessionID
 * @property {SharePermission} data.permission
 */

/**
 * OpenPad api
 * @param {String} padID
 * @returns {OpenPadResponse}
 * @example
 * const response = await api.openPad('V4f5zYX6uG')
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "padMeta": {
 *             "padID": "V4f5zYX6uG",
 *             "padName": "new_pad",
 *             "owner": "107873825748615"
 *         },
 *         "iframeLink": ":9001/p/g.0DKiUbRVAWP592ui$pad",
 *         "sessionID": "s.88dd3707683fe279b0cd9a106b2ff26e",
 *         "permission": "read_only"
 *     },
 *     "message": "Success"
 * }
 */
const openPad = async function (padID) {
  return await responseWrapper(padAPI({
    url: '/open',
    method: 'post',
    data: {
      padID: padID
    }
  }))
}

/**
 * RenamePad Response
 * @typedef {Object} RenamePadResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * RenamePad api
 * @param {String} padID
 * @param {String} padName
 * @returns {RenamePadResponse}
 * @example
 * const response = await api.renamePad('V4f5zYX6uG', 'my pad')
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const renamePad = async function (padID, padName) {
  return await responseWrapper(padAPI({
    url: '/rename',
    method: 'put',
    data: {
      padID: padID,
      padName: padName
    }
  }))
}

/**
 * VisitedPadMeta
 * @typedef {Object} VisitedPadMeta
 * @property {String} padID
 * @property {String} padName
 * @property {String} owner owner user ID
 * @property {number} lastVisitedTime millisecond timestamp
 */

/**
 * ListVisitedPads Response
 * @typedef {Object} ListVisitedPadsResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {Array.<VisitedPadMeta>} data.padMetas
 */

/**
 * ListVisitedPads api
 * @returns {ListVisitedPadsResponse}
 * @example
 * const response = await api.listVisitedPads()
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "padMetas": [
 *             {
 *                 "padID": "V4f5zYX6uG",
 *                 "padName": "pad",
 *                 "owner": "107873825748615",
 *                 "lastVisitedTime": 1646623688398
 *             },
 *             {
 *                 "padID": "IGuHiEdZRK",
 *                 "padName": "my new pad",
 *                 "owner": "107873825748615",
 *                 "lastVisitedTime": 1646622949463
 *             },
 *             {
 *                 "padID": "Arbsczw0RA",
 *                 "padName": "my new pad",
 *                 "owner": "107873825748615",
 *                 "lastVisitedTime": 1646622942444
 *             }
 *         ]
 *     },
 *     "message": "Success"
 * }
 */
const listVisitedPads = async function () {
  return await responseWrapper(padAPI({
    url: '/list_visited',
    method: 'get'
  }))
}

/**
 * OwnPadMeta
 * @typedef {Object} OwnPadMeta
 * @property {String} padID
 * @property {String} padName
 * @property {String} owner owner user ID
 * @property {number} createdTime millisecond timestamp
 * @property {number} lastEditedTime millisecond timestamp
 */

/**
 * ListOwnPads Response
 * @typedef {Object} ListOwnPadsResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {Array.<OwnPadMeta>} data.padMetas
 */

/**
 * ListOwnPads api
 * @returns {ListOwnPadsResponse}
 * @example
 * const response = await api.listOwnPads()
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "padMetas": [
 *             {
 *                 "padID": "VRDTVoRzFU",
 *                 "padName": "my_pad",
 *                 "owner": "107873825748615",
 *                 "createdTime": 1646198619455,
 *                 "lastEditedTime": 1646198619445
 *             },
 *             {
 *                 "padID": "JzLeXdjxwi",
 *                 "padName": "my_pad",
 *                 "owner": "107873825748615",
 *                 "createdTime": 1646059278932,
 *                 "lastEditedTime": 1646059278923
 *             },
 *             {
 *                 "padID": "V4f5zYX6uG",
 *                 "padName": "pad",
 *                 "owner": "107873825748615",
 *                 "createdTime": 1646059241750,
 *                 "lastEditedTime": 1646558131572
 *             }
 *         ]
 *     },
 *     "message": "Success"
 * }
 */
const listOwnPads = async function () {
  return await responseWrapper(padAPI({
    url: '/list',
    method: 'get'
  }))
}

/**
 * TrashPadMeta
 * @typedef {Object} TrashPadMeta
 * @property {String} padID
 * @property {String} padName
 * @property {String} owner owner user ID
 * @property {number} trashTime millisecond timestamp
 */

/**
 * ListTrashPads Response
 * @typedef {Object} ListTrashPadsResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {Array.<TrashPadMeta>} data.padMetas
 */

/**
 * ListTrashPads api
 * @returns {ListTrashPadsResponse}
 * @example
 * const response = await api.listTrashPads()
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "padMetas": [
 *             {
 *                 "padID": "Arbsczw0RA",
 *                 "padName": "my new pad",
 *                 "owner": "107873825748615",
 *                 "trashTime": 1646631982239
 *             },
 *             {
 *                 "padID": "CcDeG9D8l7",
 *                 "padName": "my new pad",
 *                 "owner": "107873825748615",
 *                 "trashTime": 1646631961343
 *             },
 *             {
 *                 "padID": "IGuHiEdZRK",
 *                 "padName": "my new pad",
 *                 "owner": "107873825748615",
 *                 "trashTime": 1646631918192
 *             }
 *         ]
 *     },
 *     "message": "Success"
 * }
 */
const listTrashPads = async function () {
  return await responseWrapper(padAPI({
    url: '/list_trash',
    method: 'get'
  }))
}

/**
 * DeleteVisitedPads Response
 * @typedef {Object} DeleteVisitedPadsResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * DeleteVisitedPads api
 * @param {Array.<String>} padIDs
 * @returns {DeleteVisitedPadsResponse}
 * @example
 * const response = await api.deleteVisitedPads(['V4f5zYX6uG', 'IGuHiEdZRK'])
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const deleteVisitedPads = async function (padIDs) {
  return await responseWrapper(padAPI({
    url: '/delete_visited',
    method: 'delete',
    data: {
      padIDs: padIDs
    }
  }))
}

/**
 * ThrowPadsToTrash Response
 * @typedef {Object} ThrowPadsToTrashResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * ThrowPadsToTrash api
 * @param {Array.<String>} padIDs
 * @returns {ThrowPadsToTrashResponse}
 * @example
 * const response = await api.throwPadsToTrash(['V4f5zYX6uG', 'IGuHiEdZRK'])
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const throwPadsToTrash = async function (padIDs) {
  return await responseWrapper(padAPI({
    url: '/trash',
    method: 'post',
    data: {
      padIDs: padIDs
    }
  }))
}

/**
 * RestorePadsFromTrash Response
 * @typedef {Object} RestorePadsFromTrashResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * RestorePadsFromTrash api
 * @param {Array.<String>} padIDs
 * @returns {RestorePadsFromTrashResponse}
 * @example
 * const response = await api.restorePadsFromTrash(['V4f5zYX6uG', 'IGuHiEdZRK'])
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const restorePadsFromTrash = async function (padIDs) {
  return await responseWrapper(padAPI({
    url: '/restore',
    method: 'post',
    data: {
      padIDs: padIDs
    }
  }))
}

/**
 * DeleteOwnPads Response
 * @typedef {Object} DeleteOwnPadsResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * DeleteOwnPads api (pads can be deleted only in trash)
 * @param {Array.<String>} padIDs
 * @returns {DeleteOwnPadsResponse}
 * @example
 * const response = await api.deleteOwnPads(['V4f5zYX6uG', 'IGuHiEdZRK'])
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const deleteOwnPads = async function (padIDs) {
  return await responseWrapper(padAPI({
    url: '/delete',
    method: 'delete',
    data: {
      padIDs: padIDs
    }
  }))
}

/**
 * @typedef {('no_permission'|'read_only'|'read_write'|'admin')} SharePermission
 */

/**
 * SharePad Response
 * @typedef {Object} SharePadResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * SharePad api
 * @param {String} padID
 * @param {String} shareUser share user ID
 * @param {SharePermission} permission
 * @returns {SharePadResponse}
 * @example
 * const response = await api.sharePad('V4f5zYX6uG', '107873831814029', 'read_only')
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const sharePad = async function (padID, shareUser, permission) {
  return await responseWrapper(padAPI({
    url: '/share',
    method: 'put',
    data: {
      padID: padID,
      shareUser: shareUser,
      permission: permission
    }
  }))
}

/**
 * GetPadPermission Response
 * @typedef {Object} GetPadPermissionResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {SharePermission} data.permission
 */

/**
 * GetPadPermission api
 * @param {String} padID
 * @returns {GetPadPermissionResponse}
 * @example
 * const response = await api.getPadPermission('V4f5zYX6uG')
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "permission": "admin"
 *     },
 *     "message": "Success"
 * }
 */
const getPadPermission = async function (padID) {
  return await responseWrapper(padAPI({
    url: '/permission',
    method: 'get',
    params: { padID }
  }))
}

/**
 * UserPermission
 * @typedef {Object} UserPermission
 * @property {String} userID
 * @property {SharePermission} permission
 */

/**
 * ListUserPermissions Response
 * @typedef {Object} ListUserPermissionsResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {Array.<UserPermission>} data.userPermissions
 */

/**
 * ListUserPermissions api
 * @param {String} padID
 * @returns {ListUserPermissionsResponse}
 * @example
 * const response = await api.listUserPermissions('V4f5zYX6uG')
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "userPermissions": [
 *             {
 *                 "userID": "107873825748615",
 *                 "permission": "admin"
 *             },
 *             {
 *                 "userID": "107873831814029",
 *                 "permission": "read_only"
 *             }
 *         ]
 *     },
 *     "message": "Success"
 * }
 */
const listUserPermissions = async function (padID) {
  return await responseWrapper(padAPI({
    url: '/list_user_permission',
    method: 'get',
    params: {
      padID: padID
    }
  }))
}

/**
 * IsPublicPad Response
 * @typedef {Object} IsPublicPadResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {Boolean} data.isPublic
 */

/**
 * IsPublicPad api
 * @param {String} padID
 * @returns {IsPublicPadResponse}
 * @example
 * const response = await api.isPublicPad('V4f5zYX6uG')
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "isPublic": false
 *     },
 *     "message": "Success"
 * }
 */
const isPublicPad = async function (padID) {
  return await responseWrapper(padAPI({
    url: '/is_public',
    method: 'get',
    params: {
      padID: padID
    }
  }))
}

/**
 * PublicPad Response
 * @typedef {Object} PublicPadResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * PublicPad api
 * @param {String} padID
 * @returns {PublicPadResponse}
 * @example
 * const response = await api.publicPad('V4f5zYX6uG')
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const publicPad = async function (padID) {
  return await responseWrapper(padAPI({
    url: '/public',
    method: 'put',
    data: {
      padID: padID
    }
  }))
}

/**
 * PrivatePad Response
 * @typedef {Object} PrivatePadResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * PrivatePad api
 * @param {String} padID
 * @returns {PrivatePadResponse}
 * @example
 * const response = await api.privatePad('V4f5zYX6uG')
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const privatePad = async function (padID) {
  return await responseWrapper(padAPI({
    url: '/private',
    method: 'put',
    data: {
      padID: padID
    }
  }))
}

export default {
  createPad,
  renamePad,
  openPad,
  listVisitedPads,
  listOwnPads,
  listTrashPads,
  deleteVisitedPads,
  throwPadsToTrash,
  restorePadsFromTrash,
  deleteOwnPads,
  sharePad,
  getPadPermission,
  listUserPermissions,
  isPublicPad,
  publicPad,
  privatePad
}
