import { responseWrapper } from '@/api/common'
import { urlPrefixAPI, userAPI } from '@/api/config'

/**
 * Login Response
 * @typedef {Object} LoginResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {String} data.userID
 */

/**
 * @typedef {('username'|'email')} LoginType
 */

/**
 * Login api
 * @param {LoginType} loginType
 * @param {String} loginID
 * @param {String} password
 * @returns {LoginResponse}
 * @example
 * const response = await api.login('username', 'username', 'password')
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "userID": "107873825748615"
 *     },
 *     "message": "Success"
 * }
 */
const login = async function (loginType, loginID, password) {
  return await responseWrapper(userAPI({
    url: '/login',
    method: 'post',
    data: {
      loginType: loginType,
      loginID: loginID,
      password: password
    }
  }))
}

/**
 * Logout Response
 * @typedef {Object} LogoutResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * Logout api
 * @returns {LogoutResponse}
 * @example
 * const response = await api.logout()
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const logout = async function () {
  return await responseWrapper(userAPI({
    url: '/logout',
    method: 'post'
  }))
}

/**
 * SendVerifyCode Response
 * @typedef {Object} SendVerifyCodeResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * @typedef {('email')} VerifyType
 */

/**
 * SendVerifyCode api
 * @param {VerifyType} verifyType
 * @param {String} verifyID
 * @returns {SendVerifyCodeResponse}
 * @example
 * const response = await api.sendVerifyCode()
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const sendVerifyCode = async function (verifyType, verifyID) {
  return await responseWrapper(userAPI({
    url: '/send_verify_code',
    method: 'post',
    data: {
      verifyType: verifyType,
      verifyID: verifyID
    }
  }))
}

/**
 * Register Response
 * @typedef {Object} RegisterResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {String} data.selfUserID
 */

/**
 * Register api
 * @param {String} username
 * @param {String} password
 * @param {VerifyType} verifyType
 * @param {String} verifyID
 * @param {String} verifyCode
 * @returns {RegisterResponse}
 * @example
 * const response = await api.register('username', 'password',
 *   'email', 'username@email.com', '123456')
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "userID": "107873825748615"
 *     },
 *     "message": "Success"
 * }
 */
const register = async function (username, password,
  verifyType, verifyID, verifyCode) {
  return await responseWrapper(userAPI({
    url: '/register',
    method: 'post',
    data: {
      username: username,
      password: password,
      verifyType: verifyType,
      verifyCode: verifyCode,
      verifyID: verifyID
    }
  }))
}

/**
 * FindPassword Response
 * @typedef {Object} FindPasswordResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * FindPassword api
 * @param {String} newPassword
 * @param {VerifyType} verifyType
 * @param {String} verifyID
 * @param {String} verifyCode
 * @returns {FindPasswordResponse}
 * @example
 * const response = await api.findPassword('password', 'email', 'username@email.com', '123456')
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const findPassword = async function (newPassword, verifyType, verifyID, verifyCode) {
  return await responseWrapper(userAPI({
    url: '/find_password',
    method: 'put',
    data: {
      newPassword: newPassword,
      verifyType: verifyType,
      verifyCode: verifyCode,
      verifyID: verifyID
    }
  }))
}

/**
 * ChangePassword Response
 * @typedef {Object} ChangePasswordResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * ChangePassword api
 * @param {String} oldPassword
 * @param {String} newPassword
 * @returns {ChangePasswordResponse}
 * @example
 * const response = await api.changePassword('password', 'new_password')
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const changePassword = async function (oldPassword, newPassword) {
  return await responseWrapper(userAPI({
    url: '/change_password',
    method: 'put',
    data: {
      oldPassword: oldPassword,
      newPassword: newPassword
    }
  }))
}

/**
 * ChangeEmail Response
 * @typedef {Object} ChangeEmailResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * ChangeEmail api
 * @param {String} password
 * @param {String} newEmail
 * @param {String} verifyCode
 * @returns {ChangeEmailResponse}
 * @example
 * const response = await api.changeEmail('password', 'username@email.com', '123456')
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const changeEmail = async function (password, newEmail, verifyCode) {
  return await responseWrapper(userAPI({
    url: '/change_email',
    method: 'put',
    data: {
      password: password,
      newEmail: newEmail,
      verifyCode: verifyCode
    }
  }))
}

/**
 * DeleteAccount Response
 * @typedef {Object} DeleteAccountResponse
 * @property {Number} code
 * @property {String} message
 * @property {{}} data
 */

/**
 * DeleteAccount api
 * @param {String} password
 * @returns {DeleteAccountResponse}
 * @example
 * const response = await api.deleteAccount('password')
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const deleteAccount = async function (password) {
  return await responseWrapper(userAPI({
    url: '/delete_account',
    method: 'delete',
    data: {
      password: password
    }
  }))
}

/**
 * UserBasicInfo
 * @typedef {Object} UserBasicInfo
 * @property {String} userID
 * @property {String} nickname
 * @property {String} username
 * @property {String} avatar_link
 */

/**
 * GetUserBasicInfo Response
 * @typedef {Object} GetUserBasicInfoResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {UserBasicInfo} data.userInfo
 */

/**
 * GetUserBasicInfo api
 * @param {String} userID
 * @returns {GetUserBasicInfoResponse}
 * @example
 * const response = await api.getUserBasicInfo('107873825748615')
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "userInfo": {
 *             "userID": "107873825748615",
 *             "nickname": "user",
 *             "username": "username",
 *             "avatar_link": "/api/user/avatar/8bdd7d1b-46a9-46f3-92da-72bf0c1b1f4a"
 *         }
 *     },
 *     "message": "Success"
 * }
 */
const getUserBasicInfo = async function (userID) {
  const resp = await responseWrapper(userAPI({
    url: '/info',
    method: 'get',
    params: {
      userID: userID
    }
  }))
  resp.data.userInfo.avatar_link = urlPrefixAPI + resp.data.userInfo.avatar_link
  return resp
}

/**
 * UserSelfInfo
 * @typedef {Object} UserSelfInfo
 * @property {String} userID
 * @property {String} username
 * @property {String} nickname
 * @property {String} email
 * @property {String} avatar_link
 */

/**
 * GetUserSelfInfo Response
 * @typedef {Object} GetUserSelfInfoResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {UserSelfInfo} data.userInfo
 */

/**
 * GetUserSelfInfo api
 * @returns {GetUserSelfInfoResponse}
 * @example
 * const response = await api.getUserSelfInfo()
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "userInfo": {
 *             "userID": "107873825748615",
 *             "username": "username",
 *             "nickname": "user",
 *             "email": "us****me@email.com",
 *             "avatar_link": "/api/user/avatar/8bdd7d1b-46a9-46f3-92da-72bf0c1b1f4a"
 *         }
 *     },
 *     "message": "Success"
 * }
 */
const getUserSelfInfo = async function () {
  const resp = await responseWrapper(userAPI({
    url: '/self_info',
    method: 'get'
  }))
  resp.data.userInfo.avatar_link = urlPrefixAPI + resp.data.userInfo.avatar_link
  return resp
}

/**
 * ChangeNickname Response
 * @typedef {Object} ChangeNicknameResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 */

/**
 * ChangeNickname api
 * @param {String} nickname
 * @returns {ChangeNicknameResponse}
 * @example
 * const response = await api.changeNickname('my nick name')
 * // response
 * {
 *     "code": 0,
 *     "data": {},
 *     "message": "Success"
 * }
 */
const changeNickname = async function (nickname) {
  return await responseWrapper(userAPI({
    url: '/change_nickname',
    method: 'put',
    data: {
      nickname: nickname
    }
  }))
}

/**
 * SearchUsers Response
 * @typedef {Object} SearchUsersResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {Array.<UserBasicInfo>} data.userInfos
 */

/**
 * SearchUsers api (self will not be searched)
 * @param {String} nickname nickname or username
 * @returns {SearchUsersResponse}
 * @example
 * const response = await api.searchUsers('user')
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "userInfos": [
 *             {
 *                 "userID": "107873825748615",
 *                 "nickname": "user",
 *                 "username": "username",
 *                 "avatar_link": "http://localhost/api/user/avatar/8bdd7d1b-46a9-46f3-92da-72bf0c1b1f4a"
 *             }
 *         ]
 *     },
 *     "message": "Success"
 * }
 */
const searchUsers = async function (nickname) {
  const resp = await responseWrapper(userAPI({
    url: '/search',
    method: 'get',
    params: {
      nickname: nickname
    }
  }))
  resp.data.userInfos.forEach(info => {
    info.avatar_link = urlPrefixAPI + info.avatar_link
  })
  return resp
}

/**
 * UploadAvatar Response
 * @typedef {Object} UploadAvatarResponse
 * @property {Number} code
 * @property {String} message
 * @property {Object} data
 * @property {String} data.link
 */

/**
 * UploadAvatar api
 * @param {File} file
 * @returns {UploadAvatarResponse}
 * @example
 * const response = await api.uploadAvatar(file)
 * // response
 * {
 *     "code": 0,
 *     "data": {
 *         "link": "/api/user/avatar/8bdd7d1b-46a9-46f3-92da-72bf0c1b1f4a"
 *     },
 *     "message": "Success"
 * }
 */
const uploadAvatar = async function (file) {
  const formData = new FormData()
  formData.append('file', file)
  const resp = await responseWrapper(userAPI({
    url: '/upload_avatar',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  }))
  resp.data.link = urlPrefixAPI + resp.data.link
  return resp
}

export default {
  login,
  logout,
  sendVerifyCode,
  register,
  findPassword,
  changePassword,
  changeEmail,
  deleteAccount,
  getUserBasicInfo,
  getUserSelfInfo,
  changeNickname,
  searchUsers,
  uploadAvatar
}
